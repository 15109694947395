<template>
  <div>
    <div class="col float-right">
      <q-btn-group v-show="editable&&(completeFlag||!deleteDisabled)" outline>
        <c-btn
          v-show="param.vendorFlag&&isVendorRequest"
          label="업체요청 반려"
          icon="front_hand" color="red-3"
          @btnClicked="returnDialogOpen" />
        <c-btn 
          v-show="!deleteDisabled" 
          label="위험성평가 삭제" 
          icon="remove" 
          @btnClicked="removePlan" />
        <c-btn 
          v-show="completeFlag"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="평가완료" 
          icon="check"
          @beforeAction="completeAction"
          @btnCallback="completeActionCallback" />
      </q-btn-group>
    </div>
    <c-tab
      ref="4mActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :height="height"
          :process.sync="tab.process"
          :param.sync="param"
          :planUpdateBtnData="param.planUpdateBtnData"
          @assessComplete="assessComplete"
          @research="research"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
    <q-dialog v-model="returnDialog.show" persistent>
      <q-card style="min-width: 450px">
        <q-form ref="editForm2">
          <c-card title="반려 사유" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable" 
                  :isSubmit="isReturn"
                  :url="returnUrl"
                  :param="returnData"
                  mappingType="PUT"
                  label="반려" 
                  icon="front_hand" color="red-3"
                  @beforeAction="returnAssess"
                  @btnCallback="returnAssessCallback" />
                <c-btn 
                  label='닫기' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  :rows="5"
                  label=""
                  name="returnRemark"
                  v-model="returnData.returnRemark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fm-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: '계획',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      returnData: {
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        returnRemark: '',
        chgUserId: '',
      },
      returnDialog: {
        show: false,
      },
      isVendorRequest: false,
      editable: true,
      isComplete: false,
      isReturn: false,
      listUrl: '',
      completeUrl: '',
      returnUrl: '',
      deletePlanUrl: '',
    };
  },
  computed: {
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.process.ramProcessAssessStepCd !== 'RPA0000005'
            }) === -1 
        && this.param.ramStepCd === 'RRS0000010'
    },
    assessPlan() {
      return {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        ramStepCd: this.param.ramStepCd,
        chgUserId: this.$store.getters.user.userId,
      }
    },
    deleteDisabled() {
      return this.param.ramStepCd === 'RRS0000015'
    },
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.fm.targetProcess.url;
      this.completeUrl = transactionConfig.ram.assessPlan.complete.url
      this.returnUrl = transactionConfig.ram.assessPlan.return.url
      this.deletePlanUrl = transactionConfig.ram.assessPlan.delete.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tabItems = [];
        this.$_.forEach(_result.data, item => {
          this.tabItems.push({
            key: uid(),
            name: item.processCd, icon: 'work_outline', label: item.processName, process: item, component: () => import(`${'./4mScenario.vue'}`)
          })
          this.isVendorRequest = item.vendorRequestFlag === 'Y'
        })
        this.tab = _result.data[0].processCd
        this.$refs['4mActionTab'].setValue(this.tab)
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      if (stepCd === 'RRS0000015') {
        this.$emit('emitStep', {
          name: 'stepBystep',
          param: stepCd
        })
      }
    },
    research(place) {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: place,
        }
      })
    },
    completeAction() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '완료하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessPlan.chgUserId = this.$store.getters.user.userId
          this.assessPlan.ramStepCd = 'RRS0000015'

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeActionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getList();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.assessPlan.ramStepCd
      })
    },
    returnDialogOpen() {
      this.returnDialog.show = true;
    },
    closeDialog() {
      this.returnDialog.show = false;
      Object.assign(this.$data.returnDialog, this.$options.data().returnDialog);
    },
    returnAssess() {
      if (!this.returnData.returnRemark) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '반려사유를 입력하세요.', // 반려사유를 입력하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '업체요청 반려하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.extend(this.returnData, this.assessPlan)

          this.isReturn = !this.isReturn
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    returnAssessCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.closeDialog();
      // 계획 상세 조회
      this.getList();
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deletePlanUrl, this.param.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.closePopup()
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    }
  }
};
</script>
